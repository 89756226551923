module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"D:\\Bang WEB\\apartmentsirena.com/src/intl","languages":["hr","en","it","de"],"defaultLanguage":"hr","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Apartaments and rooms Irena - Rovinj","short_name":"Apartaments & rooms Irena","start_url":"/","background_color":"#f1fcff","theme_color":"#2196f3","display":"standalone","icon":"static/imgs/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0d61bfd0239d1f0eccd46fe1fbb6c532"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
