// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-galerija-jsx": () => import("./../../../src/pages/galerija.jsx" /* webpackChunkName: "component---src-pages-galerija-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-jedinica-1-jsx": () => import("./../../../src/pages/jedinica-1.jsx" /* webpackChunkName: "component---src-pages-jedinica-1-jsx" */),
  "component---src-pages-jedinica-2-jsx": () => import("./../../../src/pages/jedinica-2.jsx" /* webpackChunkName: "component---src-pages-jedinica-2-jsx" */),
  "component---src-pages-jedinica-3-jsx": () => import("./../../../src/pages/jedinica-3.jsx" /* webpackChunkName: "component---src-pages-jedinica-3-jsx" */),
  "component---src-pages-jedinica-4-jsx": () => import("./../../../src/pages/jedinica-4.jsx" /* webpackChunkName: "component---src-pages-jedinica-4-jsx" */),
  "component---src-pages-jedinica-5-jsx": () => import("./../../../src/pages/jedinica-5.jsx" /* webpackChunkName: "component---src-pages-jedinica-5-jsx" */),
  "component---src-pages-jedinica-6-jsx": () => import("./../../../src/pages/jedinica-6.jsx" /* webpackChunkName: "component---src-pages-jedinica-6-jsx" */),
  "component---src-pages-jedinica-7-jsx": () => import("./../../../src/pages/jedinica-7.jsx" /* webpackChunkName: "component---src-pages-jedinica-7-jsx" */),
  "component---src-pages-jedinice-jsx": () => import("./../../../src/pages/jedinice.jsx" /* webpackChunkName: "component---src-pages-jedinice-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-lokacija-jsx": () => import("./../../../src/pages/lokacija.jsx" /* webpackChunkName: "component---src-pages-lokacija-jsx" */),
  "component---src-pages-obavijest-o-kolacicima-jsx": () => import("./../../../src/pages/obavijest-o-kolacicima.jsx" /* webpackChunkName: "component---src-pages-obavijest-o-kolacicima-jsx" */),
  "component---src-pages-pravila-o-privatnosti-jsx": () => import("./../../../src/pages/pravila-o-privatnosti.jsx" /* webpackChunkName: "component---src-pages-pravila-o-privatnosti-jsx" */)
}

